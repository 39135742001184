import { genericAuthorizedRequest, METHOD } from 'redux/utils/request';
import { API_URL } from 'config';

export default async (code, token) => {
  const result = await genericAuthorizedRequest(
    METHOD.GET,
    `${API_URL}/sales/leads/${code}`,
    `Bearer ${token}`
  );

  return result.data;
};
