import jwtDecode from 'jwt-decode';
import { call, takeLatest, put } from 'redux-saga/effects';
import browserHistory from 'components/root/browserHistory';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import { featureFlagsRequest } from 'app/redux/modules/FeatureFlags/actions';
import {
  applicationGetStartedSales,
  applicationGetStartedSalesIAM,
  applicationGetStartedSalesTTJWT,
} from 'app/redux/modules/App/actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { logoutUserApplication } from 'redux/modules/App/actions';
import { APP_SALES_ID_SET } from 'redux/modules/App/constants';
import {
  getLead,
  getLeadFailure,
  getLeadSuccess,
} from 'redux/modules/SalesLogin/actions';
import { salesAuthSuccess, salesAuthFailure } from './actions';
import {
  SALES_AUTH_REQUEST,
  SALES_AUTH_IAM_REQUEST,
  SALES_AUTH_TT_JWT_REQUEST,
} from './constants';
import getSalesLead from './getLead';

function* handleGetLead(token) {
  const query = new URLSearchParams(location.search);
  const originQuery = new URLSearchParams(atob(query.get('originQuery')));
  const code = originQuery.get('leadCode');

  if (!code) {
    return;
  }

  yield put(getLead());

  try {
    const lead = yield getSalesLead(code, token);

    // We want to handle if the lead is not found via the API
    if (!lead.id) {
      const error = new Error('Sales lead not found');

      Sentry.log(error, ERRORS.SALES_LEAD_NOT_FOUND);
      yield put(getLeadFailure(error, { code }));
    }
    yield put(getLeadSuccess(lead));
  } catch (error) {
    Sentry.log(error, ERRORS.SALES_LEAD_FAILURE);
    yield put(getLeadFailure(error, { code }));
  }
}

function* handleSalesAuth({ token }) {
  try {
    if (!token) {
      throw new Error('Missing authentication information');
    }

    const decoded = jwtDecode(token);
    yield put(logoutUserApplication());
    yield call(handleGetLead, token);
    yield put(applicationGetStartedSales({ token }));
    yield put(salesAuthSuccess(token, decoded.sub));
    yield put(featureFlagsRequest());
  } catch (err) {
    Sentry.log(err, ERRORS.SALES_LOGIN);
    yield put(salesAuthFailure(err));
    yield put(
      openErrorGlobalDialog('An error occurred in your log-in', err.message)
    );
  }
}

function* handleSalesAuthIAM({ token }) {
  try {
    if (!token) {
      throw new Error('Missing authentication information');
    }

    const decoded = jwtDecode(token);
    yield put(logoutUserApplication());
    yield call(handleGetLead, token);
    yield put(applicationGetStartedSalesIAM({ token }));
    yield put(salesAuthSuccess(token, decoded.sub)); // @TODO don't do this
    yield put(featureFlagsRequest());
  } catch (err) {
    Sentry.log(err, ERRORS.SALES_LOGIN);
    yield put(salesAuthFailure(err));
    yield put(
      openErrorGlobalDialog('An error occurred in your log-in', err.message)
    );
  }
}

function* handleSalesAuthTTJWT({ token }) {
  try {
    yield put(logoutUserApplication());
    yield put(applicationGetStartedSalesTTJWT({ id_token: token }));
    yield put(salesAuthSuccess(null, null));
    yield put(featureFlagsRequest());
  } catch (err) {
    Sentry.log(err, ERRORS.SALES_LOGIN);
    yield put(salesAuthFailure(err));
    yield put(
      openErrorGlobalDialog('An error occurred in your log-in', err.message)
    );
  }
}

function* handleSalesIdSet() {
  yield browserHistory.push('/');
}

export function* combinedSagas() {
  yield takeLatest(SALES_AUTH_REQUEST, handleSalesAuth);
  yield takeLatest(SALES_AUTH_IAM_REQUEST, handleSalesAuthIAM);
  yield takeLatest(SALES_AUTH_TT_JWT_REQUEST, handleSalesAuthTTJWT);
  yield takeLatest(APP_SALES_ID_SET, handleSalesIdSet);
}
