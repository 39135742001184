import { STORE_NAME } from './constants';

export default class SalesLoginApi {
  static getError(state) {
    return state.getIn([STORE_NAME, 'error']);
  }

  static getLead(state) {
    const lead = state.getIn([STORE_NAME, 'lead']);

    return lead ? lead.toJS() : null;
  }

  static isLoading(state) {
    return state.getIn([STORE_NAME, 'loading']);
  }
}
