import {
  GET_SALES_LEAD,
  GET_SALES_LEAD_FAILURE,
  GET_SALES_LEAD_SUCCESS,
  SALES_AUTH_REQUEST,
  SALES_AUTH_IAM_REQUEST,
  SALES_AUTH_TT_JWT_REQUEST,
  SALES_AUTH_SUCCESS,
  SALES_AUTH_FAILURE,
} from './constants';

export const getLead = () => ({ type: GET_SALES_LEAD });

export const getLeadFailure = (error, lead) => ({
  type: GET_SALES_LEAD_FAILURE,
  lead,
  error,
});

export const getLeadSuccess = (lead) => ({
  type: GET_SALES_LEAD_SUCCESS,
  lead,
});

export const salesAuthRequest = (token) => ({
  type: SALES_AUTH_REQUEST,
  token,
});

export const salesAuthIAMRequest = (token) => ({
  type: SALES_AUTH_IAM_REQUEST,
  token,
});

export const salesAuthTTJWTRequest = (token) => ({
  type: SALES_AUTH_TT_JWT_REQUEST,
  token,
});

export const salesAuthSuccess = (token, email) => ({
  type: SALES_AUTH_SUCCESS,
  token,
  email,
});

export const salesAuthFailure = (error) => ({
  type: SALES_AUTH_FAILURE,
  error,
});
