export const STORE_NAME = 'SalesLogin';
export const SALES_AUTH_STORE_KEY = 'sales';
export const GET_SALES_LEAD = 'GET_SALES_LEAD';
export const GET_SALES_LEAD_FAILURE = 'GET_SALES_LEAD_FAILURE';
export const GET_SALES_LEAD_SUCCESS = 'GET_SALES_LEAD_SUCCESS';
export const SALES_AUTH_REQUEST = 'SalesLogin/SALES_AUTH_REQUEST';
export const SALES_AUTH_IAM_REQUEST = 'SalesLogin/SALES_AUTH_IAM_REQUEST';
export const SALES_AUTH_TT_JWT_REQUEST = 'SalesLogin/SALES_AUTH_TT_JWT_REQUEST';
export const SALES_AUTH_SUCCESS = 'SalesLogin/SALES_AUTH_SUCCESS';
export const SALES_AUTH_FAILURE = 'SalesLogin/SALES_AUTH_FAILURE';

export const SALES_LOGOUT = 'SalesLogin/LOGOUT';
