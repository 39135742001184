import Immutable from 'immutable';

import {
  GET_SALES_LEAD,
  GET_SALES_LEAD_FAILURE,
  GET_SALES_LEAD_SUCCESS,
  SALES_AUTH_REQUEST,
  SALES_AUTH_IAM_REQUEST,
  SALES_AUTH_TT_JWT_REQUEST,
  SALES_AUTH_SUCCESS,
  SALES_AUTH_FAILURE,
  SALES_AUTH_STORE_KEY,
} from './constants';

import {
  failedRequestState,
  successRequestState,
  sendingRequestState,
} from '../../utils/isRequestInProgress';

export const createInitial = () => ({
  email: '',
  error: null,
  lead: null,
  loading: false,
  token: '',
  requestStatus: {
    [SALES_AUTH_STORE_KEY]: '',
  },
});

const initialState = Immutable.fromJS(createInitial());

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SALES_LEAD:
      return state.merge({
        loading: true,
      });

    case GET_SALES_LEAD_FAILURE:
      return state.merge({
        error: action.error,
        lead: action.lead,
        loading: false,
      });

    case GET_SALES_LEAD_SUCCESS:
      return state.merge({
        error: null,
        lead: action.lead,
        loading: false,
      });

    case SALES_AUTH_REQUEST:
    case SALES_AUTH_IAM_REQUEST:
    case SALES_AUTH_TT_JWT_REQUEST:
      return sendingRequestState(state, SALES_AUTH_STORE_KEY);

    case SALES_AUTH_SUCCESS:
      return successRequestState(state, SALES_AUTH_STORE_KEY).merge({
        email: action.email,
        token: action.token,
      });

    case SALES_AUTH_FAILURE:
      return failedRequestState(state, SALES_AUTH_STORE_KEY).merge({
        error: action.error,
      });

    default:
      return state;
  }
}
